<template>
  <div class="member-shop-point-exchange-center">
    <PageTitle title="會員中心佈告欄" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <BaseElInput
        v-model="search.title"
        clearable
        placeholder="輸入佈告欄標題"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="search.actionType"
        clearable
        placeholder="選擇點擊前往類型"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <BaseElSelectOption
          v-for="type in actionTypeConfig"
          :key="type.value"
          :label="type.label"
          :value="type.value"
        />
      </BaseElSelect>
    </FiltersContainer>
    <MemberCenterBulletinTable v-loading="loading.table" :tableData="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import MemberCenterBulletinTable from './components/MemberCenterBulletinTable.vue'
import { GetMemberCenterAd, GetMemberCenterAdCount } from '@/api/bulletin'
import { actionTypeConfig } from '@/config/bulletin'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'MemberCenterBulletinList',
  components: { PageTitle, MemberCenterBulletinTable, FiltersContainer },
  setup (props) {
    const router = useRouter()
    const { shopId, loading, tableData, tableDataCount, tableOptions, pageStartIndex, search, extendData, fetchData, fetchDataCount } = useTable()
    extendData('search', {
      title: null,
      actionType: null,
    })
    const onCreate = () => {
      router.push({ name: 'MemberCenterBulletinEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        title: search.title || undefined,
        actionType: search.actionType || undefined,
      }
      await Promise.allSettled([
        fetchData(GetMemberCenterAd, payload),
        fetchDataCount(GetMemberCenterAdCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })

    return { onCreate, search, tableDataCount, tableOptions, tableData, refresh, loading, actionTypeConfig }
  },
})
</script>

  <style lang="postcss" scoped>

  </style>
