<template>
  <div class="member-shop-point-exchange-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="佈告欄名稱" prop="title" align="center" />
      <BaseElTableColumn label="顯示狀態" align="center" width="120px">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'published') ? 'action' : 'info'">{{ get(scope.row, 'published') ? '顯示' : '隱藏' }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="排序" prop="order" align="center" width="120px" />
      <BaseElTableColumn label="版型" prop="type" align="center" width="120px" />
      <BaseElTableColumn label="點擊前往" prop="actionType" align="center" width="120px" />
      <BaseElTableColumn label="佈告開始時間" prop="publishedStartAt" align="center" width="180px" />
      <BaseElTableColumn label="結束時間" prop="publishedEndAt" align="center" width="180px" />
      <BaseElTableColumn label="操作" align="center" fixed="right" width="100px">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @delete="onRowDelete(scope.row)"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      :loading="loading.delete"
      @close="modal.delete = false"
      @delete="deleteData"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { DeleteMemberCenterAd } from '@/api/bulletin'
import Tag from '@/components/Tag/Tag.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { formatDate } from '@/utils/date'
import { actionTypeConfig, layoutConfig } from '@/config/bulletin'

export default defineComponent({
  name: 'MemberCenterBulletinTable',
  components: { EmptyBlock, Tag, TableEditBtnGroup, DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const loading = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      return map(data, i => {
        return {
          title: i.title,
          published: i.published,
          order: i.order,
          type: get(layoutConfig, `${i.type}.label`),
          actionType: get(actionTypeConfig, `${i.actionType}.label`),
          publishedStartAt: i.publishedStartAt ? formatDate(i.publishedStartAt) : '-',
          publishedEndAt: i.publishedEndAt ? formatDate(i.publishedEndAt) : '-',
          id: i.id,
        }
      })
    })
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberCenterBulletinEdit',
        params: { id: row.id },
      })
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteData = async () => {
      if (loading.delete) return
      loading.delete = true
      const [, err] = await DeleteMemberCenterAd({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      loading.delete = false
      if (err) return window.$message.error(err)
      modal.delete = false
      window.$message.success('刪除成功！')
      emit('refresh')
    }

    return { get, loading, displayData, selectRow, modal, deleteData, onRowEdit, onRowDelete, formatDate }
  },
})
</script>

<style lang="postcss" scoped>

</style>
